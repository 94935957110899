import Container from "@material-ui/core/Container";
import CssBaseline from "@material-ui/core/CssBaseline";
import Grid from "@material-ui/core/Grid";
import Paper from "@material-ui/core/Paper";
import { makeStyles, ThemeProvider } from "@material-ui/core/styles";
import Typography from "@material-ui/core/Typography";
import React from "react";
import facebookIcon from "../../../../../Content/images/facebook.png";
import pinterestIcon from "../../../../../Content/images/pinterest.png";
import twitterIcon from "../../../../../Content/images/twitter.png";
import HomeCard from "../../components/HomeCard/HomeCard.jsx";
import HomeHero from "../../components/HomeHero/HomeHero.jsx";
import HomeSection from "../../components/HomeSection/HomeSection.jsx";
import { GOOGLE_FONT_URL } from "../../data/StringConstants";
import useAddScript from "../../hooks/useAddScript.js";
import Footer from "../../layouts/Main/components/Footer/Footer";
import NavBarLandingPage from "../../layouts/Main/components/NavBar/NavBarLandingPage.jsx";
import customTheme from "../../theme/customTheme/customTheme.js";
import CustomButton from "../../components/CustomButton/CustomButton";
import { featuredCards, footerData, hyperlinks, sectionHeadings } from "./homeLandingPageData.js";

const useStyles = makeStyles(theme => ({
	root: {}
}));

export default function HomeLandingPage() {
	// Append the twitter widget script tag to the page after we finish rendering.
	useAddScript("https://platform.twitter.com/widgets.js");
	const classes = useStyles();

	const contentUri = "/Content/images/";

	return (
		<ThemeProvider theme={customTheme}>
			<React.Fragment>
				<link
					rel="stylesheet"
					href={GOOGLE_FONT_URL}
				/>
				<CssBaseline />

				<NavBarLandingPage />

				<Container maxWidth="lg" className={classes.root}>
					<div id="home">
						<HomeHero />
					</div>

					<HomeSection
						id="features"
						sectionTitle={sectionHeadings.ExpandYourLibrarysReach}
					>
						<Grid container spacing={6} alignItems="stretch">
							{featuredCards.map(cardData => (
								<Grid
									item
									xs={12}
									sm={4}
									md={4}
									lg={4}
									key={cardData.title}
									style={{ display: "flex" }}
								>
									<HomeCard
										key={cardData.title}
										cardContent={cardData}
									/>
								</Grid>
							))}
						</Grid>
					</HomeSection>

					<HomeSection
						id="social"
						sectionTitle={sectionHeadings.Social}
					>
						<Grid container justify="space-between">
							<Grid
								item
								container
								direction="column"
								item
								xs={12}
								md={6}
							>
								<Typography
									variant="h5"
									component="h2"
									gutterBottom
								>
									Find and follow us on your favorite social
									platforms. You can also check out some of
									our latest designs on Pinterest.
								</Typography>
								<Grid container spacing={6} className="social-links">
									<Grid item>
										<a
											href={hyperlinks.social.facebook}
											target="_blank"
										>
											<img
												src={`${contentUri}${facebookIcon}`}
												alt="LibraryAware Facebook"
											/>
										</a>
									</Grid>
									<Grid item>
										<a
											href={hyperlinks.social.twitter}
											target="_blank"
										>
											<img
												src={`${contentUri}${twitterIcon}`}
												alt="LibraryAware Twitter"
											/>
										</a>
									</Grid>
									<Grid item>
										<a
											href={hyperlinks.social.pinterest}
											target="_blank"
										>
											<img
												src={`${contentUri}${pinterestIcon}`}
												alt="LibraryAware Pinterest"
											/>
										</a>
									</Grid>
								</Grid>
							</Grid>

							<Grid item xs={12} md={5}>
								<Paper>
									<a
										className="twitter-timeline"
										data-width="500"
										data-height="500"
										data-theme="light"
										href="https://twitter.com/LibraryAware?ref_src=twsrc%5Etfw"
									></a>
								</Paper>
							</Grid>
						</Grid>
					</HomeSection>

					<HomeSection
						id="news"
						sectionTitle={sectionHeadings.LearnMore}
					>
						<Grid container spacing={6}>
							<Grid item xs={12} sm={8}>
								<Typography variant="h5" component="h2">
									Contact us to learn more about how we can
									help you serve your community.
								</Typography>
							</Grid>
							<Grid item xs={12} sm={4}>
								<CustomButton
									variant="contained"
									color="primary"
									size="large"
									href={
										hyperlinks.learnMoreSection.requestInfo
									}
									target="_blank"
									fullWidth
								>
									Request Info
								</CustomButton>
							</Grid>
							<Grid item xs={12} sm={8}>
								<Typography variant="h5" component="h2">
									Check out our product demo to see what
									LibraryAware can do for you.
								</Typography>
							</Grid>
							<Grid item xs={12} sm={4}>
								<CustomButton
									variant="outlined"
									color="primary"
									size="large"
									href={hyperlinks.learnMoreSection.seeDemo}
									target="_blank"
									fullWidth
								>
									See a Demo
								</CustomButton>
							</Grid>
						</Grid>
					</HomeSection>

					<HomeSection
						id="info"
						sectionTitle={sectionHeadings.Contact}
					>
						<Grid container spacing={6}>
							<Grid item xs={12} sm={8}>
								<Typography variant="h5" component="h2">
									Keep up with new product features, seasonal
									templates, and everything that's new with
									LibraryAware.
								</Typography>
							</Grid>
							<Grid item xs={12} sm={4}>
								<CustomButton
									variant="outlined"
									color="primary"
									size="large"
									href={hyperlinks.contactSection.newsletter}
									target="_blank"
									fullWidth
								>
									Subscribe to our Newsletter
								</CustomButton>
							</Grid>
							<Grid item xs={12} sm={8}>
								<Typography variant="h5" component="h2">
									Get tips on readers' advisory and library
									marketing, sign up for webinars, and find
									information on NoveList product updates.
								</Typography>
							</Grid>
							<Grid item xs={12} sm={4}>
								<CustomButton
									variant="outlined"
									color="primary"
									size="large"
									href={hyperlinks.contactSection.latestPost}
									target="_blank"
									fullWidth
								>
									Read the Latest
								</CustomButton>
							</Grid>
						</Grid>
					</HomeSection>
				</Container>

				{/* Footer */}
				<Footer {...footerData}></Footer>
			</React.Fragment>
		</ThemeProvider>
	);
}
