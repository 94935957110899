import AppBar from "@material-ui/core/AppBar";
import Container from "@material-ui/core/Container";
import Link from "@material-ui/core/Link";
import { makeStyles } from "@material-ui/core/styles";
import Toolbar from "@material-ui/core/Toolbar";
import React from "react";
import * as Constants from "../../../../data/StringConstants";
import LibraryAwareLogoV2 from '../../../../components/LibraryAwareLogo/LibraryAwareLogoV2';

const useStyles = makeStyles(theme => ({
	root: {
		flexGrow: 1
	},
	appBar: {
		background: theme.palette.brandingV2.HeaderBackgroundColor
	},
	container: {
		display: "flex",
		flexDirection: "column"
	},
	toolbar: {
		flexWrap: "wrap"
	},
	toolbarLogo: {
		flexGrow: 1
	},
	link: {
		margin: theme.spacing(1, 3.5),
		color: theme.palette.brandingV2.DarkFontColor
	},
	linkButton: {
		margin: theme.spacing(1, 3.5),
		backgroundColor: theme.palette.brandingV2.PrimaryButton.BackgroundColor,
		color: theme.palette.brandingV2.PrimaryButton.FontColor,
		"&:focus,&:hover": { 
            backgroundColor: theme.palette.brandingV2.PrimaryButton.ActiveBackgroundColor,
        }
	},
	logo: {
		height: 40,
		marginTop: 10
	}
}));

export default function NavBarLandingPage() {
	const classes = useStyles();

	return (
		<div className={classes.root}>
			<AppBar position="fixed" className={classes.appBar}>
				<Container className={classes.container}>
					<Toolbar className={classes.toolbar}>
						<div className={classes.toolbarLogo}>
							<a href="#home">
								<LibraryAwareLogoV2 className={classes.logo}/>
							</a>
						</div>
						<nav>
							<Link
								variant="button"
								href="#features"
								color="inherit"
								className={classes.link}
							>
								{Constants.NAV_LINK_FEATURES}
							</Link>
							<Link
								variant="button"
								color="inherit"
								href="#social"
								className={classes.link}
							>
								{Constants.NAV_LINK_SOCIAL}
							</Link>
							<Link
								variant="button"
								color="inherit"
								href="#news"
								className={classes.link}
							>
								{Constants.NAV_LINK_NEWS}
							</Link>
							<Link
								variant="button"
								color="inherit"
								href="#info"
								className={classes.link}
							>
								{Constants.NAV_LINK_INFO}
							</Link>
						</nav>
						<a data-testid="nov-bar-login" 
							className={`MuiButtonBase-root MuiButton-root MuiButton-contained ${classes.linkButton}`} 
							href="login" 
							title="Login">Login</a>
					</Toolbar>
				</Container>
			</AppBar>
		</div>
	);
}
